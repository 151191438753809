import { sha256 } from "js-sha256";
import { ArrayEquals } from "../utils";
import { CURRENT_GEM_FILE_MAJOR, GEM_FABRIC_FILENAME, GEM_FILE_MAGIC } from "./constants";
import { UnzipFileInfo, unzipSync } from "fflate";
import { FabricFile } from "./types";
import { FileNotFoundError, InvalidOperationError } from "../errors";

export class GemFile {

  private file: File;
  private zipData: Uint8Array | null;

  constructor(file: File) {
    this.file = file;
    this.zipData = null;
  }

  public InitializeGemFile = async (): Promise<boolean> => {
    const buffer = await this.file.arrayBuffer();
    const magicData = new Int8Array(buffer, 0, GEM_FILE_MAGIC.length);

    const sizeOfUInt32 = 4;
    const sizeOfUInt64 = 8;
    const sizeOfSha256 = 32;

    const magicEquals = ArrayEquals(magicData, GEM_FILE_MAGIC);

    const versionData = new Int32Array(buffer, GEM_FILE_MAGIC.length, 2);
    const major = versionData[0];

    const zipSizeData = new BigUint64Array(buffer, GEM_FILE_MAGIC.length + sizeOfUInt32 * 2, 1);
    const zipSize = zipSizeData[0];
    const fileData = new Uint8Array(buffer, 0, this.file.size - sizeOfSha256);

    const sha = sha256.create();
    sha.update(fileData);
    const computedHash = sha.digest();
    const headerSize = GEM_FILE_MAGIC.length + sizeOfUInt32 * 2 + sizeOfUInt64;

    this.zipData = fileData.slice(headerSize, this.file.size - sizeOfSha256);

    const fileHash = Array.from(new Uint8Array(buffer.slice(buffer.byteLength - sizeOfSha256, buffer.byteLength)));
    const hashEquals = ArrayEquals(fileHash, computedHash);

    const validated = zipSize > 0 && CURRENT_GEM_FILE_MAJOR >= major && hashEquals && magicEquals;

    return validated;
  };

  public GetFabricData = () : FabricFile => {
    return JSON.parse(new TextDecoder().decode(this.GetFileFromGem(GEM_FABRIC_FILENAME)));
  };

  public GetFileFromGem = (fileName: string) : Uint8Array => {
    if (!this.zipData) {
      throw new InvalidOperationError("Tried to read from uninitialized GEM file. Please call InitializeGemFile first");
    }

    const unzipped = unzipSync(this.zipData, {
      filter(file: UnzipFileInfo) {
        return file.name === fileName;
      }
    });

    const fileData = unzipped[fileName];

    if (!fileData) {
      throw new FileNotFoundError(`${fileName} was not found in GEM file`);
    }

    return fileData;
  };

}