import {
  Box,
  Chip,
  Divider,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import { Navigate, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import moment from 'moment';
import { CopyAllOutlined, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from "@mui/icons-material";
import { gunzipSync } from "fflate";
import { useSearchParams } from "react-router-dom";
import {
  AgentStatusToColor,
  AgentStatusToString,
  ApiEnabledPage,
  AuthHelper,
  ElipsisWrappedTypography,
  GetPage, GetPageSize,
  IsJobInEndState,
  RenderAgent, RenderJob,
  TimeSince,
  UserAccount,
  FindItemWithGuid,
  RenderJobStatusToName,
  RenderJobStatusToColor,
  GetOrderByIndex,
  GetOrderByDirection,
  LoginState,
  SetOrderByIndex,
  SetCurrentPage,
  SetOrderByDirection,
  SetPageSize } from "../../../../WebCommon/src";
import { DEFAULT_PAGE } from "./constants";
import { TopBar, TableColumnData, SortableSearchableTable } from "../components";

interface AgentStatusProps {
  readonly agent: RenderAgent
}
const logout = (authHelper: AuthHelper, setIsLoggedIn: (state: boolean) => void) => {
  authHelper.Logout();
  setIsLoggedIn(false);

};

const AgentStatus = (props: AgentStatusProps) => {
  const { agent } = props;

  return (
    <Paper sx={{ flex: 1, mr: "1vmin", padding: "1vmin" }} elevation={5}>
      <Typography textAlign={"center"} variant="h4">{agent.Name}</Typography>
      <Divider />
      <Typography display={"inline-block"}>
        Status:
      </Typography>
      <Chip color={AgentStatusToColor(agent.Status)} label={AgentStatusToString(agent.Status)} />
      <br />
      <Typography display={"inline-block"}>Id: </Typography><Chip label={agent.id} />
      <br />
      <Typography display={"inline-block"}>Last Pinged: </Typography><Chip label={moment(agent.LastPing).fromNow()} />
      <br />
      <Typography display={"inline-block"}>Queue Size: </Typography><Chip label={agent.MaxQueuedJobs} />
      <br />

    </Paper>);
};

const DEFAULT_LOG_TEXT = "Downloading log...";

interface AdminPageProps extends ApiEnabledPage {
  readonly authHelper: AuthHelper
}

export const AdminPage = (props: AdminPageProps) => {
  const pageSizeOptions: number[] = [25, 50, 100, 1000];
  const { authHelper, baseApiUrl } = props;

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(authHelper.IsLoggedIn());
  const [renderAgents, setRenderAgents] = useState<RenderAgent[]>([]);
  const [userAccounts, setUserAccounts] = useState<UserAccount[]>([]);
  const [numJobs, setNumJobs] = useState<number>(-1);

  const [open, setOpen] = useState<string | null>(null);
  const [logRetrieved, setLogRetrieved] = useState<boolean>(false);
  const [logText, setLogText] = useState<string>(DEFAULT_LOG_TEXT);
  const [tableJobs, setTableJobs] = useState<RenderJob[]>([]);

  const [searchParams,] = useSearchParams();
  const DEFAULT_PAGE_SIZE = 25;

  const page = GetPage(numJobs, pageSizeOptions, DEFAULT_PAGE_SIZE, DEFAULT_PAGE, searchParams);
  const pageSize = GetPageSize(pageSizeOptions, DEFAULT_PAGE_SIZE, searchParams);

  const TableColumnData: TableColumnData<RenderJob>[] = [{
    ExpandButton: true,
    Index: 0,
    Sortable: false,
    Width: "1vw",
    ValueGetter: (job) => {
      return (
        <IconButton
          disabled={!IsJobInEndState(job)}
          onClick={() => onClickExpand(job)}
          size="small">{(open && open === job.id) ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
        </IconButton>);

    }
  }, {
    Index: 1,
    DisplayName: "Name",
    PropertyName: "Name",
    Sortable: true,
    Width: "2vw",
    ValueGetter: (job) => <ElipsisWrappedTypography Width="3VW">{job.Name}</ElipsisWrappedTypography>,
  }, {
    Index: 2,
    DisplayName: "Id",
    PropertyName: "id",
    Sortable: true,
    Width: "1vw",
    ValueGetter: (job) => <ElipsisWrappedTypography noWrap Width="2VW">{job.id}</ElipsisWrappedTypography>
  }, {
    Index: 3,
    DisplayName: "Submitted",
    PropertyName: "Submitted",
    Sortable: true,
    Width: "3vw",
    ValueGetter: (job) => <TimeSince fontSize="1.0rem" Width="4VW" Date={job.Submitted} />
  }, {
    Index: 4,
    DisplayName: "Assigned",
    PropertyName: "Assigned",
    Sortable: true,
    Width: "3vw",
    ValueGetter: (job) => <TimeSince fontSize="1.0rem" Width="4VW" Date={job.Assigned} />
  }, {
    Index: 5,
    DisplayName: "Started",
    PropertyName: "Started",
    Sortable: true,
    Width: "2vw",
    ValueGetter: (job) => <TimeSince fontSize="1.0rem" Width="4VW" Date={job.Started} />
  }, {
    Index: 6,
    DisplayName: "Queued",
    PropertyName: "Queued",
    Sortable: true,
    Width: "2vw",
    ValueGetter: (job) => <TimeSince fontSize="1.0rem" Width="4VW" Date={job.Queued} />
  }, {
    Index: 7,
    DisplayName: "Crashed",
    PropertyName: "Crashed",
    Sortable: true,
    Width: "3vw",
    ValueGetter: (job) => <TimeSince fontSize="1.0rem" Width="4VW" Date={job.Crashed} />
  }, {
    Index: 8,
    DisplayName: "Cancelled",
    PropertyName: "Cancelled",
    Sortable: true,
    Width: "3vw",
    ValueGetter: (job) => <TimeSince fontSize="1.0rem" Width="4VW" Date={job.Cancelled} />
  }, {
    Index: 9,
    DisplayName: "Deleted",
    PropertyName: "Deleted",
    Sortable: true,
    Width: "2vw",
    ValueGetter: (job) => <TimeSince fontSize="1.0rem" Width="4VW" Date={job.Deleted} />
  }, {
    Index: 10,
    DisplayName: "Agent",
    PropertyName: "AssignedAgent",
    Sortable: true,
    Width: "2vw",
    ValueGetter: (job) => {
      const agentHolder = FindItemWithGuid(job.AssignedAgent, renderAgents);
      if (agentHolder) {
        const agent = agentHolder as RenderAgent;
        return <ElipsisWrappedTypography Width="3vw">{agent.Name}</ElipsisWrappedTypography>;
      }
    }
  }, {
    Index: 11,
    DisplayName: "Resolution",
    PropertyName: "Resolution",
    Sortable: true,
    Width: "3vw",
    ValueGetter: (job) => {
      return (<ElipsisWrappedTypography Width="1vw">{job.Resolution}</ElipsisWrappedTypography>);
    }
  }, {
    Index: 12,
    DisplayName: "User",
    PropertyName: "Owner",
    Sortable: true,
    Width: "2vw",
    ValueGetter: (job) => {
      const accountHolder = FindItemWithGuid(job.Owner, userAccounts);
      if (accountHolder) {
        const account = accountHolder as UserAccount;
        return <ElipsisWrappedTypography Width="4vw">{account.Email}</ElipsisWrappedTypography>;
      }
    }
  }, {
    Index: 13,
    DisplayName: "Status",
    PropertyName: "Status",
    Sortable: true,
    Width: "2vw",
    ValueGetter: (job) => {
      return (<Chip label={RenderJobStatusToName(job.Status)} color={RenderJobStatusToColor(job.Status)} />);
    }
  }, {
    Index: 14,
    DisplayName: "Status Message",
    PropertyName: "StatusMessage",
    Sortable: true,
    Width: "4vw",
    ValueGetter: (job) => {
      return (<ElipsisWrappedTypography Width="1vw">{job.StatusMessage}</ElipsisWrappedTypography>);

    }
  }, {
    Index: 15,
    DisplayName: "Completion",
    PropertyName: "Completion",
    Sortable: true,
    Width: "3vw",
    ValueGetter: (job) => {
      return (<ElipsisWrappedTypography Width="1vw">{Math.round(job.Completion * 100)}</ElipsisWrappedTypography>);
    }
  }, {
    Index: 16,
    DisplayName: "Job Data",
    PropertyName: "JobData",
    Sortable: true,
    Width: "3vw",
    ValueGetter: (job) => {
      return (<ElipsisWrappedTypography noWrap Width="1VW">{job.JobData}</ElipsisWrappedTypography>);
    }
  }, {
    Index: 17,
    DisplayName: "pk",
    PropertyName: "pk",
    Sortable: true,
    Width: "1vw",
    ValueGetter: (job) => {
      return (<ElipsisWrappedTypography Width="1vw">{job.pk}</ElipsisWrappedTypography>);
    }
  },
  ];

  const orderByIndex = GetOrderByIndex(TableColumnData.length, 3, searchParams);
  const orderByDirection = GetOrderByDirection(searchParams, "asc");

  const fetchJobs = async (
    page: number,
    pageSize: number,
    baseApiUrl: string,
    loginState: LoginState | null): Promise<RenderJob[]> => {
    if (loginState) {
      setTableJobs([]);
      const queryParams = `page=${page}&pageSize=${pageSize}&orderBy=${TableColumnData[orderByIndex].PropertyName}` +
        `&direction=${orderByDirection.toUpperCase()}&all=true`;

      const url = `GetJobs?${queryParams}`;
      const res = await fetch(baseApiUrl + url, {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Authentication": loginState.apiKey
        }
      });

      if (res.status === 200) {
        return (await res.json()) as RenderJob[];
      } else if (res.status === 401) {
        logout(authHelper, setIsLoggedIn);
      }
    }

    return [];
  };

  useEffect(() => {

    fetchJobs(page, pageSize, baseApiUrl, loginState).then((jobs) => setTableJobs(jobs));

  }, [orderByDirection, orderByIndex, page, pageSize, renderAgents]);

  const navigate = useNavigate();

  const loginState = authHelper.GetLoginState();

  const fetchLog = async (jobId: string, setText: (text: string) => void) => {

    if (!loginState) {
      return;
    }

    const urlGetRes = await fetch(baseApiUrl + `Management/GetLogUrl/${jobId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authentication: loginState.apiKey
      }
    });

    if (urlGetRes.status === 200) {
      const json = await urlGetRes.json();

      const logDownloadRes = await fetch(json.Uri, {
        method: "GET",
        headers: {
          Accept: "application/octet-stream"
        }
      });

      if (logDownloadRes.status === 200) {
        const logGzip = await logDownloadRes.blob();
        if (logGzip.size !== 0) {
          const array = new Uint8Array(await logGzip.arrayBuffer());
          const unzipped = gunzipSync(array);
          const decoder = new TextDecoder();
          const logString = decoder.decode(unzipped);
          setText(logString);
          setLogRetrieved(true);
        } else {
          setText("Log was empty");
          setLogRetrieved(false);
        }
      } else if (logDownloadRes.status === 401) {
        logout(authHelper, setIsLoggedIn);
      }
    } else if (urlGetRes.status === 404) {
      setText("No log to download");
      setLogRetrieved(false);
    } else if (urlGetRes.status === 401) {
      logout(authHelper, setIsLoggedIn);

    }
  };

  const onClickExpand = async (Job: RenderJob) => {
    const newValue = open ? null : Job.id;
    const opening = newValue ? true : false;
    setOpen(newValue);
    if (opening) {
      setLogRetrieved(false);
      setLogText(DEFAULT_LOG_TEXT);
      await fetchLog(Job.id, setLogText);
    }
  };

  const onClickSort = async (Index: number) => {
    if (Index === orderByIndex) {
      SetOrderByDirection(searchParams, orderByDirection === "asc" ? "desc" : "asc", navigate);
      SetOrderByIndex(Index, TableColumnData.length, searchParams, navigate);

    } else {
      SetOrderByDirection(searchParams, orderByDirection, navigate);
      SetOrderByIndex(Index, TableColumnData.length, searchParams, navigate);
    }
  };

  const fetchAgents = async () => {

    if (loginState) {
      const res = await fetch(baseApiUrl + "Management/GetRenderAgentInfo", {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Authentication": loginState.apiKey
        }
      });

      if (res.status === 200) {
        const agents = (await res.json()) as RenderAgent[];
        setRenderAgents(agents);
      } else if (res.status === 401) {
        logout(authHelper, setIsLoggedIn);
      }
    }
  };

  const fetchAccounts = async () => {
    if (loginState) {
      const res = await fetch(baseApiUrl + "Management/GetAccounts", {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Authentication": loginState.apiKey
        }
      });

      if (res.status === 200) {
        const accounts = (await res.json()) as UserAccount[];
        setUserAccounts(accounts);
      } else if (res.status === 401) {
        logout(authHelper, setIsLoggedIn);
      }
    }
  };

  useEffect(() => {
    fetchAccounts();

  }, []);

  if (!isLoggedIn) {
    return <Navigate to="/Login" />;
  }

  if (!loginState?.isAdmin) {
    return <Navigate to="/" />;
  } else {
    useEffect(() => {
      const interval = setInterval(() => {
      }, 30 * 1000);

      fetchAgents();

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }, []);

    useEffect(() => {
      fetch(baseApiUrl + "GetNumJobs?all=true", {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authentication: loginState.apiKey
        }
      }).then(res => {

        if (res.status === 200) {
          res.json().then(json => {
            setNumJobs(+json.Count);

          });
        } else if (res.status === 401) {
          logout(authHelper, setIsLoggedIn);
        }
      });

    }, [tableJobs]);

    const noRenderAgents = renderAgents.length === 0;
    const renderAgentCards = renderAgents.map((agent: RenderAgent) => {
      return (<AgentStatus agent={agent} key={agent.id} />);
    });

    const skeleton =
      <Stack flexGrow={1} direction="row">
        <Paper>
          <Skeleton variant="rectangular" />
          <Divider />
          <Skeleton variant="rectangular" />
        </Paper>

      </Stack>;

    return (
      <Box>
        <TopBar authHelper={authHelper} logout={() => {
          authHelper.Logout();
          setIsLoggedIn(false);
        }} />

        <Typography textAlign="center" variant="h3">Render Agent Status</Typography>
        <Stack mb="1vmin" direction="row">
          {noRenderAgents ? skeleton : renderAgentCards}
        </Stack>
        <Box>

          <SortableSearchableTable
            ControlsAtTop
            TableColumnData={TableColumnData}
            NumTotalItems={numJobs}
            Page={page}
            className={`jobList jobList-max-dims jobList-prod-dims-adminPage`}
            PageSize={pageSize}
            SetPage={(val) => SetCurrentPage(val, numJobs, searchParams, pageSizeOptions, DEFAULT_PAGE_SIZE, navigate)}
            SetPageSize={(val) => SetPageSize(val, pageSizeOptions, DEFAULT_PAGE_SIZE, searchParams, navigate)}
            PageSizeOptions={pageSizeOptions}
            OnClickSort={onClickSort}
            OrderBy={{ Dir: orderByDirection, Index: orderByIndex }}
            DataItems={tableJobs}
            ExpandedRow={open}
            HeaderFontSize="1.0rem"
            ExpandedContent={
              <>
                {
                  logRetrieved ?
                    <> <Typography display={"inline"}>Copy log to clipboard</Typography>
                      <IconButton onClick={() => {
                        navigator.clipboard.writeText(logText);
                      }} title="Copy log to clipboard"><CopyAllOutlined />
                      </IconButton>
                    </> : ""
                }
                <pre>{logText} </pre>
              </>}
          />

        </Box>
      </Box>);
  }
};
