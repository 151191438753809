import { AuthHelper, GuidHolder, RenderJobStatus } from "../utils";

export interface ApiEnabledPage {
  readonly baseApiUrl: string;
  readonly authHelper: AuthHelper;
}

export type ProjectData = {
  Name: string;
  Images?: Record<string, File> | null;
  Json?: File;
  GemFile?: File;
};

export enum FileType {
  WeavePattern,
  WarpFilCoupe,
  WeftFilCoupe,
}

type FabricColor = {
  RelativeValue: number;
  MetallicIntensity: number;
  Code: string;
  Name: string;
  RGB: number[];
  CIE_Lab: number[];
};

type FabricFiber = {
  Percentage: number;
  Code: string;
  Name: string;
};
type BurrData = {
  BurrsPerM: number;
  MaterialProfileIndex: number;
  LengthMinMM: number;
  LengthMaxMM: number;
  ThicknessMinMM: number;
  ThicknessMaxMM: number;
  KnotDensity: number;
};

type SlubData = {
  AvgLengthMM: number;
  VarianceLengthMM: number;
  AvgDistanceMM: number;
  VarianceDistanceMM: number;
  SizeMultiplier: number;
  SizeVariance: number;
  TwistDivisor: number;
  Shape: number;
  Pack: boolean;
};

type PlyData = {
  MaterialProfileIndex: number;
  TwistsPerM: number;
  SizeRatio: number;
  FilamentChaos: number;
  FlyawayRadiusMM: number;
  VarFlyawayRadiusMM: number;
  FlyawayLengthMM: number;
  FlyawayChaos: number;
  FlyawayFactor: number;
  FlyawayShape: number;
  LoopFlyawayDensityPerMM: number;
  LoopFlyawayMigrationMax: number;
  FiberDensity: number;
  FiberCount: number;
  BurrData: BurrData;
  SlubData: SlubData;
};

type ChenilleData = {
  MaterialProfileIndex: number;
  PileThicknessMM: number;
  PileVariance: number;
  PileWavelengthMM: number;
  PileModulation: number;
  FiberDensityMM: number;
  LateralAmplitude: number;
  LateralPhase: number;
  LateralChaos: number;
  RotationChaos: number;
  Clumping: number;
};

type FabricThread = {
  ID: string;
  ThreadShape: string;
  ThicknessMM: number;
  ThreadWidthMM: number;
  ThreadHeightMM: number;
  ThreadCompressionFactor: number;
  ThreadFreedom: number;
  PlyCompressionFactor: number;
  MonoFilament: boolean;
  MonoFilamentProfileIndex: number;
  Ply: number;
  PlyTwistPerM: number;
  TextureImage: string;
  YarnTextureLenghtMM: number;
  YarnTextureMultiply: number;
  FilamentShape: string;

  YarnCode: string;
  ColorCode: string;
  Count: string;
  TwistsPerM: number;
  TwistsPerMm: number;
  TwistSense: string;
  Luster: number;
  MetallicZoom: number;
  VariationPercent: number;
  HairType: number;
  HairLength: number;
  HairProbability: number;
  MelangeCorrelation: number;
  Model: number;
  Transparency: number;
  Colors: FabricColor[];
  BasicColor: number;
  Fiber: FabricFiber[];
  PlyDatas: PlyData[];
  ChenilleData: ChenilleData;
};

type FabricVarient = {
  Name: string;
  Threads: FabricThread[];
};

type FabricPattern = {
  SimulationRepeat: number;
  WeaveOffset: number;
  RaisedFinishTenthMM: number;
  RaisedFinishStrenght: number;
  AdditionalFrindgeThreads: number;
  RaisedFinishDirection: number;
  FilCoupe: string;
  Pattern: string;
  YarnPositionsAbsoluteCm: number[];
  YarnPositionsScaleFactor: number;
  DefaultVariant: number;
  Variants: FabricVarient[];

  WeavingChaosWavelengthLF: number;
  WeavingChaosAmpLF: number;
  WeavingChaosWavelengthHF: number;
  WeavingChaosAmpHF: number;
  WeavingRotationDegrees: number;
  AvgYarnClumpSize: number;
  VarYarnClumpSize: number;
  YarnClumpFactor: number;
  AvgFloatRotationDisplacementMM: number;
  VarFloatRotationDisplacementMM: number;
};

export type FabricSettings = {
  RoundDistanceFraction: number;
  OutputFile: string;
  PatternRepeat: number;
  RandomiseInstancePositionsmm: number;
  SeamlessThreads: number;
  WarpWeftTensionFactor: number;
  CurvePointsPerTwist: number;
};
export type Vector3 = {
  X: number;
  Y: number;
  Z: number;
};

export type Transform = {
  Position: Vector3;
  Rotation: Vector3;
};

export enum ViewType {
  Fabric,
  Yarn,
  Model,
}

export type CardData = {
  WidthMM: number;
  HeightMM: number;
  ThreadThicknessMM: number;
  Seed: number;
  CrossingsCount: number;
};

export enum YarnRenderType {
  Single,
  PlainWeave,
  Card,
  Generic,
  Knitted,
}

export type PlainWeaveData = {
  Count: number;
};

export type SingleYarnData = {
  SizeMM: number;
};

export enum KnittingPattern {
  GarterStitch,
  StockinetteStitch,
  Rib1By1,
  Rib2By2,
  BrokenRib,
  SeedStitch,
}

export type KnittedYarnData = {
  RowsCount: number;
  StitchesCount: number;
  RowsPerCm: number;
  StitchesPerCm: number;
  Pattern: KnittingPattern;
  ThreadThicknessMm: number;
  StitchShape: number;
  StitchSpacingMm: number;
};

export type YarnPreview = {
  YarnIndex: number;
  PreviewType: YarnRenderType;
  Card: CardData;
  PlainWeave: PlainWeaveData;
  SingleYarn: SingleYarnData;
  KnittedYarn: KnittedYarnData;
};

export type SceneInfo = {
  Camera: Transform;
  ViewType: ViewType;
  YarnPreview: YarnPreview;
};

export type MaterialData = {
  FiberThicknessUMMin: number;
  FiberThicknessUMMax: number;
  FiberLengthMMMin: number;
  FiberLengthMMMax: number;
  Shader: string;
  Roughness: number;
  Metalness: number;
  ColorARGB: number;
  FiberWidthUM: number;
  FiberHeightUM: number;
  FiberLengthMM: number;
  DensityGPerCM3: number;
  Code: string;
  Name: string;
  CanBeDyed: boolean;
};

export type MaterialWithPercentage = {
  Percentage: number;
  Material: MaterialData;
};

export type MaterialProfile = {
  Name: string;
  MonoFilament: boolean;
  Materials: MaterialWithPercentage[];
};
export type FabricFile = {
  Language: string;
  Weave: string;
  BackgroundRGB: number[];
  YarnCurvePrecision: number;
  Ruler: number;
  YarnLabels: number;
  ScreenDPI: number;
  PBRtextureBorder: number;
  PBRtexturePixelDimension: number;
  YarnEdges: number;
  VirtualYarnZoomFactor: number;
  Fringe: number;
  Warp: FabricPattern;
  Weft: FabricPattern;
  Settings: FabricSettings;
  SceneInfo: SceneInfo;
  MaterialProfiles: MaterialProfile[];
};

export interface RenderJobInfo extends GuidHolder {
  Name: string;
  Submitted: Date;
  Status: RenderJobStatus;
  Completion: number;
  StatusMessage: string;
}

export enum AccountCreationError {
  Success = 0,
  EmailInUse = 1,
  DeviceInUse = 2,
}

export enum LoginError {
  Success = 0,
  BadLogin = 1,
  DeviceInUse = 2,
}

export interface LoginParams {
  Email: string;
  Password: string;
  DeviceId?: string;
  ClientSecret?: string;
}
