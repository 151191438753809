import { AuthHelper, GuidHolder, RenderJobStatus } from "../utils";

export interface ApiEnabledPage {
  readonly baseApiUrl: string;
  readonly authHelper: AuthHelper
}

export type ProjectData = {
  Name: string
  Images?: Record<string, File> | null;
  Json?: File;
  GemFile?: File;
}

export enum FileType {
  WeavePattern,
  WarpFilCoupe,
  WeftFilCoupe
}

type FabricColor = {
  RelativeValue: number,
  MetallicIntensity: number,
  Code: string,
  Name: string,
  RGB: number[],
  CIE_Lab: number[]
};

type FabricFiber = {
  Percentage: number,
  Code: string,
  Name: string
};

type FabricThread = {
  ID: string,
  ThicknessMM: number,
  ThreadCompressionFactor: number,
  YarnCode: string,
  ColorCode: string,
  Count: string,
  Ply: number,
  TwistsPerM: number,
  TwistSense: string,
  Luster: number,
  MetallicZoom: number,
  VariationPercent: number,
  HairType: number,
  HairLength: number,
  HairProbability: number,
  MelangeCorrelation: number,
  Model: number,
  Transparency: number,
  Colors: FabricColor[],
  Fiber: FabricFiber
};

type FabricVarient = {
  Name: string,
  Threads: FabricThread[],

}

type FabricPattern = {
  SimulationRepeat: number,
  WeaveOffset: number,
  RaisedFinishTenthMM: number,
  RaisedFinishStrenght: number,
  RaisedFinishDirection: number,
  FilCoupe: string,
  Pattern: string,
  YarnPositionsAbsoluteCm: number[],
  DefaultVariant: number,
  Variants: FabricVarient[]

}

export type FabricFile = {
  Language: string,
  Weave: string,
  BackgroundRGB: number[],
  YarnEdges: number,
  YarnCurvePrecision: number,
  Ruler: number,
  Fringe: number,
  YarnLabels: number,
  ScreenDPI: number,
  PBRtexturePixelDimension: number,
  VirtualYarnZoomFactor: number,
  Warp: FabricPattern,
  Weft: FabricPattern,
}

export interface RenderJobInfo extends GuidHolder {
  Name: string;
  Submitted: Date,
  Status: RenderJobStatus;
  Completion: number;
  StatusMessage: string;
}

export enum AccountCreationError {
  Success = 0,
  EmailInUse = 1,
  DeviceInUse = 2
}

export enum LoginError {
  Success = 0,
  BadLogin = 1,
  DeviceInUse = 2
}

export interface LoginParams {
  Email: string,
  Password: string,
  DeviceId?: string,
  ClientSecret?: string
}
