import { sha256 } from "js-sha256";
import { ArrayEquals } from "../utils";
import {
  CURRENT_GEM_FILE_MAJOR,
  CURRENT_GEM_FILE_MINOR,
  GEM_FABRIC_FILENAME,
  GEM_FILE_MAGIC,
} from "./constants";
import { UnzipFileInfo, unzipSync, zipSync } from "fflate";
import { FabricFile } from "./types";
import {
  FileNotFoundError,
  InvalidOperationError,
  KeyNotFoundError,
} from "../errors";

import { Buffer as BrowserBuffer } from "buffer/";
export class GemFile {
  private buffer: Uint8Array;
  private zipData: Uint8Array | null;
  private unzippedFiles: Record<string, Uint8Array>;
  private readonly sizeOfUInt32 = 4;
  private readonly sizeOfUInt64 = 8;
  private readonly sizeOfSha256 = 32;
  constructor(buffer: Uint8Array) {
    this.buffer = buffer;
    this.zipData = null;
    this.unzippedFiles = {};
  }

  public InitializeGemFile = async (): Promise<boolean> => {
    const magicData = new Uint8Array(
      this.buffer.buffer,
      0,
      GEM_FILE_MAGIC.length
    );

    const magicEquals = ArrayEquals(magicData, GEM_FILE_MAGIC);

    const versionData = new Uint32Array(
      this.buffer.buffer,
      GEM_FILE_MAGIC.length,
      2
    );
    const major = versionData[0];
    const minor = versionData[1];

    const zipSizeData = new BigUint64Array(
      this.buffer.buffer,
      GEM_FILE_MAGIC.length + this.sizeOfUInt32 * 2,
      1
    );
    const zipSize = zipSizeData[0];
    const fileData = new Uint8Array(
      this.buffer.buffer,
      0,
      this.buffer.byteLength - this.sizeOfSha256
    );

    const sha = sha256.create();
    sha.update(fileData);
    const computedHash = sha.digest();
    const headerSize =
      GEM_FILE_MAGIC.length + this.sizeOfUInt32 * 2 + this.sizeOfUInt64;

    this.zipData = fileData.slice(
      headerSize,
      this.buffer.byteLength - this.sizeOfSha256
    );

    const fileHash = Array.from(
      new Uint8Array(
        this.buffer.slice(
          this.buffer.byteLength - this.sizeOfSha256,
          this.buffer.byteLength
        )
      )
    );
    const hashEquals = ArrayEquals(fileHash, computedHash);

    const validated =
      zipSize > 0 &&
      CURRENT_GEM_FILE_MAJOR >= major &&
      CURRENT_GEM_FILE_MINOR >= minor &&
      hashEquals &&
      magicEquals;

    if (validated) {
      const unzipped = unzipSync(this.zipData);
      const keys = Object.keys(unzipped);

      keys.forEach((key) => this.GetFileFromGem(key));
    }

    return validated;
  };

  public GetFabricData = (): FabricFile | null => {
    const fileData = this.GetFileFromGem(GEM_FABRIC_FILENAME);

    if (!fileData) {
      return null;
    } else {
      return JSON.parse(new TextDecoder().decode(fileData));
    }
  };

  public GetFileFromGem = (fileName: string): Uint8Array | null => {
    if (!this.zipData) {
      throw new InvalidOperationError(
        "Tried to read from uninitialized GEM file. Please call InitializeGemFile first"
      );
    }

    if (this.unzippedFiles[fileName]) {
      return this.unzippedFiles[fileName];
    }

    const unzipped = unzipSync(this.zipData, {
      filter(file: UnzipFileInfo) {
        return file.name === fileName;
      },
    });

    const fileData = unzipped[fileName];

    if (!fileData) {
      throw new FileNotFoundError(`${fileName} was not found in GEM file`);
    }

    this.unzippedFiles[fileName] = fileData;

    return fileData;
  };

  private write64 = (buf: BrowserBuffer, value: bigint, offset: number) => {
    let lo = Number(value & BigInt(4294967295));
    buf[offset++] = lo;
    lo = lo >> 8;
    buf[offset++] = lo;
    lo = lo >> 8;
    buf[offset++] = lo;
    lo = lo >> 8;
    buf[offset++] = lo;
    let hi = Number((value >> BigInt(32)) & BigInt(4294967295));
    buf[offset++] = hi;
    hi = hi >> 8;
    buf[offset++] = hi;
    hi = hi >> 8;
    buf[offset++] = hi;
    hi = hi >> 8;
    buf[offset++] = hi;
    return offset;
  };

  public Serialize = (): Uint8Array => {
    const zipData = BrowserBuffer.from(zipSync(this.unzippedFiles));

    const fileSize =
      GEM_FILE_MAGIC.length +
      this.sizeOfUInt32 * 2 +
      this.sizeOfUInt64 +
      zipData.byteLength +
      this.sizeOfSha256;

    const buffer = BrowserBuffer.alloc(fileSize);
    let bufferCursor = 0;
    for (
      bufferCursor = 0;
      bufferCursor < GEM_FILE_MAGIC.length;
      bufferCursor++
    ) {
      buffer.writeInt8(GEM_FILE_MAGIC[bufferCursor], bufferCursor);
    }

    buffer.writeUInt32LE(CURRENT_GEM_FILE_MAJOR, bufferCursor);

    bufferCursor += this.sizeOfUInt32;

    buffer.writeUInt32LE(CURRENT_GEM_FILE_MINOR, bufferCursor);

    bufferCursor += this.sizeOfUInt32;
    this.write64(buffer, BigInt(zipData.byteLength), bufferCursor);
    bufferCursor += this.sizeOfUInt64;

    zipData.copy(buffer, bufferCursor, 0, zipData.byteLength);

    bufferCursor += zipData.byteLength;

    const sha = sha256.create();
    sha.update(buffer.slice(0, bufferCursor));
    const computedHash = sha.digest();
    for (let i = 0; i < computedHash.length; i++) {
      buffer.writeUInt8(computedHash[i], bufferCursor++);
    }

    return buffer;
  };

  public UpdateFileAtPath = (path: string, data: Uint8Array): void => {
    if (this.unzippedFiles[path] === undefined) {
      throw new KeyNotFoundError(`${path} was not found in record.`);
    }

    this.unzippedFiles[path] = data;
  };
}
