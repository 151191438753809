import { Alert, Box, Button, Collapse, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Navigate } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ApiEnabledPage, LoginError, LoginParams, PasswordHideAdornment } from "../../../../WebCommon/src";

export interface LoginPageProps extends ApiEnabledPage {
  readonly deviceLogin?: boolean
}

export const LoginPage = (props: LoginPageProps) => {

  const { deviceLogin, baseApiUrl, authHelper } = props;

  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [emailValue, setEmailValue] = useState<string>("");

  const [passwordError, setPasswordError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(authHelper.IsLoggedIn());

  const [isLoginDisabled, setIsLoginDisabled] = useState<boolean>(false);
  const [isLoginFailed, setIsLoginFailed] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<LoginError>(LoginError.Success);
  const [searchParams,] = useSearchParams();

  const navigate = useNavigate();

  const onPasswordChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const password = event.target.value;
    setPasswordValue(password);
  };

  const onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const email = event.target.value;
    setEmailValue(email);
  };

  const enterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  const onSubmit = async () => {
    if (emailValue === "") {
      setEmailError("Please enter an email");
    } else {
      setEmailError("");
    }

    if (passwordValue === "") {
      setPasswordError("Please enter a password");
    } else {
      setPasswordError("");
    }

    setIsLoginDisabled(true);

    const data: LoginParams = {
      Email: emailValue,
      Password: passwordValue,
    };

    const deviceId = searchParams.get("device");
    const secret = searchParams.get("secret");

    if (deviceId && secret) {
      data.DeviceId = deviceId;
      data.ClientSecret = secret;
    }

    const loginResponse = await fetch(baseApiUrl + "Login", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (loginResponse.status === 200) {
      const json = await loginResponse.json();
      const redirectUri = searchParams.get("redirectUri");

      if (redirectUri) {
        const redirectRes = await fetch(`${redirectUri}?key=${json.apiKey}`);

        if (redirectRes && redirectRes.status === 200) {
          authHelper.Login(
            json.apiKey,
            json.email,
            json.isAdmin);
          setIsLoggedIn(true);
          setIsLoginFailed(false);
        }
      } else {
        authHelper.Login(
          json.apiKey,
          json.email,
          json.isAdmin);
        setIsLoggedIn(true);
        setIsLoginFailed(false);
      }

    } else if (loginResponse.status === 401) {
      const json = await loginResponse.json();
      setLoginError(json.error);
      setIsLoginFailed(true);
    } else {
      setIsLoginFailed(true);
    }
    setIsLoginDisabled(false);

  };

  if (isLoggedIn && !deviceLogin) {
    return <Navigate replace to="/" />;
  } else {

    return (
      <Box className="loginSignupBox">
        <Typography variant="h4">Login</Typography>
        <br />
        <Stack>
          <TextField
            required
            autoComplete="username"
            helperText={emailError}
            error={emailError === "" ? false : true}
            onKeyDown={enterKeyDown}
            onChange={onEmailChanged}
            sx={{ mb: "1vh" }}
            label="Email" />
          <TextField
            required
            helperText={passwordError}
            error={passwordError === "" ? false : true}
            onKeyDown={enterKeyDown}
            onChange={onPasswordChanged}
            label="Password"
            InputProps={{
              endAdornment: <PasswordHideAdornment hidePassword={hidePassword} setHidePassword={setHidePassword} />,
            }}
            type={hidePassword ? "password" : "text"}
            autoComplete="current-password"
            sx={{ "mb": "2vh" }}
          />
          <Button disabled={isLoginDisabled} onClick={onSubmit} variant="contained">Login</Button>
          <Typography>{"Need an account? Create one "}
            <a className="link" onClick={() => {
              navigate({
                pathname: "/Register",
                search: `?${searchParams}`,
              });
            }}>here
            </a>.
          </Typography>
          <Collapse in={isLoginFailed}>
            <Alert variant="outlined" severity="error">
              {loginError === LoginError.BadLogin
                ? "Incorrect email or password."
                : "Device already registered to another account."}
            </Alert>
          </Collapse>
        </Stack>
      </Box>);
  }
};

export default LoginPage;
