export const CURRENT_GEM_FILE_MAJOR: number = 0;
export const CURRENT_GEM_FILE_MINOR: number = 1;
export const GEM_FABRIC_FILENAME: string = "Fabric.json";
export const GEM_FILE_MAGIC: number[] =
    [0x46,
      0x41,
      0x42,
      0x52,
      0x49,
      0x43,
      0x0,
      0x0];